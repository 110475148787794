<template>
    <div v-if="institutions">
        <div v-for="institution in institutions" :key="institution.id" class="mt-3">
            <router-link class="btn institution_box w-100"
                :to="{ name: 'AdminInstitution', params: { institution_id: institution.id } }">
                <div class="row align-items-center g-0">
                    <div class="col-6 text-start">
                        <span class="fw-bold">{{ institution.name }}</span>
                        <div>
                            <span class="pe-2">{{ $t('company.plan') }}:</span>
                            <span v-if="institution.plan">{{ institution.plan.plan }}</span>
                            <span v-else>-</span>
                        </div>
                        <div>
                            <span class="pe-2">{{ $t('institution.invoicing.paidTo') }}:</span>
                            <i18n-d v-if="institution.paid_to" tag="span"
                                :value="new Date(institution.paid_to)"></i18n-d>
                            <span v-else>-</span>
                        </div>
                        <div>
                            <span class="pe-2">Počet zaplacených uživatelů:</span>
                            <span v-if="institution.paid_users">{{ institution.paid_users }}</span>
                            <span v-else>-</span>
                        </div>
                    </div>
                    <div class="col-3 d-flex justify-content-center align-items-center border_left_right py-2">
                        <UserAvatar v-if="institution.sales_representative" :user="institution.sales_representative"/>
                        <span v-else>Nemá obchodního zástupce</span>
                    </div>
                    <div class="col-3 d-flex justify-content-center align-items-center py-2">
                        <UserAvatar v-if="institution.support" :user="institution.support"/>
                        <span v-else>Nemá podporu</span>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
    import UserAvatar from '../CompanyDetail/UserAvatar.vue';

    export default {
        name: "InstitutionsList",
        components: {
            UserAvatar
        },
        props: {
            institutions: {
                type: Array,
                required: true,
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/abstracts/variables";

    .institution_box {
        background-color: $white;
        color: $black;
    }
    .border_left_right {
        border-left: 1px solid $light-grey;
        border-right: 1px solid $light-grey;
        padding-left: 10px;
        padding-right: 10px;
    }
</style>