<template>
    <div class="row justify-content-center">
        <div class="col-10 institutions">
            <div class="row block w-100">
                <div class="d-flex align-items-center justify-content-between">
                    <h3 class="me-3">{{ $t('admin.institutions')
                        }}</h3>
                    <router-link :to="{ name: 'AdminAddInstitution' }" class="btn btn_main">
                        {{ $t("utils.addInstitution") }}
                    </router-link>
                </div>
                <div class="mt-3">
                    <input v-model="search" class="form-control" :placeholder="$t('admin.search')">
                </div>

                <InstitutionsList :institutions="filteredInstitutions"/>

            </div>
        </div>
    </div>
</template>

<script>
    import UserAvatar from "../components/CompanyDetail/UserAvatar.vue";
    import InstitutionsList from "../components/Institution/InstitutionsList.vue";

    export default {
        name: 'Admin',
        components: {
            UserAvatar,
            InstitutionsList
        },
        data() {
            return {
                institutions: [],
                search: '',
            }
        },
        created() {
            this.loadData()
        },
        computed: {
            filteredInstitutions() {
                // Return institutions that match the search term (case insensitive)
                return this.institutions.filter(institution =>
                    institution.name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        },
        methods: {
            loadData() {
                this.$store.getters.api.get("/institutions").then(response => {
                    this.institutions = response.data.items;
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/abstracts/variables";

    .institution_box {
        background-color: $white;
        color: $black;
    }
    .border-left-right {
        border-left: 1px solid $light-grey;
        border-right: 1px solid $light-grey;
        padding-left: 10px;
        padding-right: 10px;
    }
</style>