<template>
    <div class="container mt-4">
        <div class="row justify-content-center align-items-center g-3 mb-3">
            <div class="col-6">
                <div class="square_block payment_block">
                    <div class="label">Částka za minulý měsíc</div>
                    <div class="value">{{ formattedTotalPaymentLastMonth }}</div>
                </div>
            </div>
            <div class="col-6">
                <div class="square_block payment_block">
                    <div class="label">Částka za tento měsíc</div>
                    <div class="value">{{ formattedTotalPaymentThisMonth }}</div>
                </div>
            </div>
        </div>

        <LineChart :series="seriesData"/>

         <!-- Section: Sales representative -->
        <div class="section mb-5 mt-4" v-if="paidUsersLastMonthSalesRepresentative > 0 || paidUsersThisMonthSalesRepresentative > 0">
            <h2 class="headline">Obchod</h2>
            <div class="row justify-content-center align-items-center g-3 mb-3">
                <div class="col-12 col-lg-6 col-xl-3">
                    <div class="square_block">
                        <div class="label">Počet uživatelů za minulý měsíc</div>
                        <div class="value">{{ paidUsersLastMonthSalesRepresentative }} už./den</div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-3">
                    <div class="square_block payment_block">
                        <div class="label">Částka za minulý měsíc</div>
                        <div class="value">{{ formattedPaymentLastMonthSalesRepresentative }}</div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-3">
                    <div class="square_block">
                        <div class="label">Počet uživatelů za tento měsíc</div>
                        <div class="value">{{ paidUsersThisMonthSalesRepresentative }} už./den</div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-3">
                    <div class="square_block payment_block">
                        <div class="label">Částka za tento měsíc</div>
                        <div class="value">{{ formattedPaymentThisMonthSalesRepresentative }}</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Section: Support -->
        <div class="section mb-5 mt-4" v-if="paidUsersLastMonthSupport > 0 || paidUsersThisMonthSupport > 0">
            <h2 class="headline">Podpora</h2>
            <div class="row justify-content-center align-items-center g-3 mb-3">
                <div class="col-12 col-lg-6 col-xl-3">
                    <div class="square_block">
                        <div class="label">Počet uživatelů za minulý měsíc</div>
                        <div class="value">{{ paidUsersLastMonthSupport }} už./den</div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-3">
                    <div class="square_block payment_block">
                        <div class="label">Částka za minulý měsíc</div>
                        <div class="value">{{ formattedPaymentLastMonthSupport }}</div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-3">
                    <div class="square_block">
                        <div class="label">Počet uživatelů za tento měsíc</div>
                        <div class="value">{{ paidUsersThisMonthSupport }} už./den</div>
                    </div>
                </div> 
                <div class="col-12 col-lg-6 col-xl-3">
                    <div class="square_block payment_block">
                        <div class="label">Částka za tento měsíc</div>
                        <div class="value">{{ formattedPaymentThisMonthSupport }}</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Section: Project manager -->
        <div class="section mb-5 mt-4" v-if="paymentLastMonthManager > 0 || paymentThisMonthManager > 0">
            <h2 class="headline">Projektový manažer</h2>
            <div class="row justify-content-center align-items-center g-3 mb-3">
                <div class="col-12 col-lg-6 col-xl-3">
                    <div class="square_block">
                        <div class="label">Počet uživatelů za minulý měsíc</div>
                        <div class="value">{{ paidUsersLastMonthManager }} už./den</div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-3">
                    <div class="square_block payment_block">
                        <div class="label">Částka za minulý měsíc</div>
                        <div class="value">{{ formattedPaymentLastMonthManager }}</div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-3">
                    <div class="square_block">
                        <div class="label">Počet uživatelů za tento měsíc</div>
                        <div class="value">{{ paidUsersThisMonthManager }} už./den</div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 col-xl-3">
                    <div class="square_block payment_block">
                        <div class="label">Částka za tento měsíc</div>
                        <div class="value">{{ formattedPaymentThisMonthManager }}</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Section: Institutions -->
        <div v-if="institutions.length > 0" class="section mb-5 mt-4">
            <h2 class="headline">Instituce</h2>
            <div class="row">
                <div class="col-12">
                    <InstitutionsList :institutions="institutions"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import LineChart from '../components/LineChart.vue';
    import InstitutionsList from '../components/Institution/InstitutionsList.vue';

    export default {
        components: {
            LineChart,
            InstitutionsList
        },
        data() {
            return {
                paidUsersLastMonthSalesRepresentative: 0,
                paidUsersThisMonthSalesRepresentative: 0,
                paymentLastMonthSalesRepresentative: 0,
                paymentThisMonthSalesRepresentative: 0,
                paidUsersLastMonthSupport: 0,
                paidUsersThisMonthSupport: 0,
                paymentLastMonthSupport: 0,
                paymentThisMonthSupport: 0,
                paidUsersLastMonthManager: 0,
                paidUsersThisMonthManager: 0,
                paymentLastMonthManager: 0,
                paymentThisMonthManager: 0,
                chartDataSalesRepresentative: [],
                chartDataSupport: [],
                chartDataManager: [],
                seriesData: [],
                institutions: [],
            }
        },
        created() {
            this.loadData();
        },
        methods: {
            loadData() {
                this.$store.getters.api.get("/stats/user/sales_representative")
                    .then(response => {
                        this.paidUsersLastMonthSalesRepresentative = response.data.paid_users_last_month
                        this.paidUsersThisMonthSalesRepresentative = response.data.paid_users_this_month
                        this.paymentLastMonthSalesRepresentative = response.data.payment_last_month
                        this.paymentThisMonthSalesRepresentative = response.data.payment_this_month

                        this.chartDataSalesRepresentative = response.data.chart_data.map(item => ({
                            date: item.date,
                            paidUsers: item.total_paid_users
                        }));
                        
                        this.updateSeriesData();
                    })
                    .catch(error => {
                        console.error("Error loading data:", error)
                    });

                this.$store.getters.api.get("/stats/user/support")
                    .then(response => {
                        this.paidUsersLastMonthSupport = response.data.paid_users_last_month
                        this.paidUsersThisMonthSupport = response.data.paid_users_this_month
                        this.paymentLastMonthSupport = response.data.payment_last_month
                        this.paymentThisMonthSupport = response.data.payment_this_month

                        this.chartDataSupport = response.data.chart_data.map(item => ({
                            date: item.date,
                            paidUsers: item.total_paid_users
                        }));

                        this.updateSeriesData();
                    })
                    .catch(error => {
                        console.error("Error loading data:", error)
                    });

                this.$store.getters.api.get("/stats/user/manager")
                    .then(response => {
                        this.paidUsersLastMonthManager = response.data.paid_users_last_month
                        this.paidUsersThisMonthManager = response.data.paid_users_this_month
                        this.paymentLastMonthManager = response.data.payment_last_month
                        this.paymentThisMonthManager = response.data.payment_this_month

                        this.chartDataManager = response.data.chart_data.map(item => ({
                            date: item.date,
                            paidUsers: item.total_paid_users
                        }));

                        this.updateSeriesData()
                    })
                    .catch(error => {
                        console.error("Error loading data:", error)
                    });

                this.$store.getters.api.get("institutions/user")
                    .then(response => {
                        this.institutions = response.data.items;
                    })
                    .catch(error => {
                        console.error("Error loading institutions:", error)
                    })
            },
            formatAmount(value) {
                return (
                    new Intl.NumberFormat("cs-CZ", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    }).format(value) + " Kč"
                );
            },
            updateSeriesData() {
                let series = [
                    {
                        name: "Obchodní zástupce",
                        data: this.chartDataSalesRepresentative.map(item => [new Date(item.date).getTime(), item.paidUsers])
                    },
                    {
                        name: "Podpora",
                        data: this.chartDataSupport.map(item => [new Date(item.date).getTime(), item.paidUsers])
                    }
                ];

                if (this.hasProjectManagerData) {
                    series.push({
                        name: "Projektový manažer",
                        data: this.chartDataManager.map(item => [new Date(item.date).getTime(), item.paidUsers])
                    });
                }
                
                this.seriesData = series;
            }
        },
        computed: {
            hasProjectManagerData() {
                const hasNonZeroPayments =
                    this.paymentLastMonthManager > 0 || this.paymentThisMonthManager > 0;

                const hasValidChartData =
                    Array.isArray(this.chartDataManager) &&
                    this.chartDataManager.length > 0;

                return hasNonZeroPayments && hasValidChartData;
            },
            formattedTotalPaymentLastMonth() {
                return this.formatAmount(this.paymentLastMonthSalesRepresentative + this.paymentLastMonthSupport + this.paymentLastMonthManager)
            },
            formattedTotalPaymentThisMonth() {
                return this.formatAmount(this.paymentThisMonthSalesRepresentative + this.paymentThisMonthSupport + this.paymentThisMonthManager)
            },
            formattedPaymentLastMonthSalesRepresentative() {
                return this.formatAmount(this.paymentLastMonthSalesRepresentative)
            },
            formattedPaymentThisMonthSalesRepresentative() {
                return this.formatAmount(this.paymentThisMonthSalesRepresentative)
            },
            formattedPaymentLastMonthSupport() {
                return this.formatAmount(this.paymentLastMonthSupport)
            },
            formattedPaymentThisMonthSupport() {
                return this.formatAmount(this.paymentThisMonthSupport)
            },
            formattedPaymentLastMonthManager() {
                return this.formatAmount(this.paymentLastMonthManager)
            },
            formattedPaymentThisMonthManager() {
                return this.formatAmount(this.paymentThisMonthManager)
            },
            seriesData() {
                const allDates = new Set([
                    ...this.chartDataSalesRepresentative.map(item => new Date(item.date).getTime()),
                    ...this.chartDataSupport.map(item => new Date(item.date).getTime()),
                    ...this.chartDataManager.map(item => new Date(item.date).getTime())
                ]);

                let series = [
                    {
                        name: "Obchodní zástupce",
                        data: [...allDates].map(date => {
                            const found = this.chartDataSalesRepresentative.find(item => new Date(item.date).getTime() === date);
                            return [date, found ? found.paidUsers : 0]; // Pokud datum chybí, vrátí 0
                        })
                    },
                    {
                        name: "Podpora",
                        data: [...allDates].map(date => {
                            const found = this.chartDataSupport.find(item => new Date(item.date).getTime() === date);
                            return [date, found ? found.paidUsers : 0]; // Pokud datum chybí, vrátí 0
                        })
                    },
                ];

                if (this.hasProjectManagerData) {
                    series.push({
                        name: "Projektový manažer",
                        data: [...allDates].map(date => {
                            const found = this.chartDataManager.find(item => new Date(item.date).getTime() === date);
                            return [date, found ? found.paidUsers : 0];
                        })
                    });
                }

                return series;
            }
        }
    }
</script>

<style scoped>
    .headline {
        text-align: center;
        font-weight: bold;
        font-size: 48px;
        margin-bottom: 20px;
    }
    .square_block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 10px;
        width: 100%;
        background-color: #FFFFFF;
        height: 150px;
        border-radius: 10px;
    }
    .label {
        color: #555555;
        font-weight: bold;
        margin-bottom: 5px;
    }
    .value {
        font-size: 30px;        
        font-weight: bold;
        color: black;
        white-space: nowrap;
    }
    .payment_block {
        background-color:rgb(231, 204, 231);
    }
</style>