<template>
    <div v-if="institution" class="content">
        <div>
            <h2>{{ institution.name }}</h2>
            <p>Obchodní zástupce:
                <span v-if="institution.sales_representative">{{ institution.sales_representative.name }}</span>
                <span v-else>Nepřiřazen</span>
            </p>
            <p>Podpora:
                <span v-if="institution.support">{{ institution.support.name }}</span>
                <span v-else>Nepřiřazena</span>
            </p>
        </div>
        <div class="mt-4">
            <div class="mt-2 block" v-if="institution.customer">
                <div class="text-center mt-2">
                    <h3 class="title">Fakturační údaje</h3>
                </div>
                <table>
                    <tr>
                        <td>Firma:</td>
                        <td class="ps-2">{{ institution.customer.company_name }}</td>
                    </tr>
                    <tr>
                        <td>IČO:</td>
                        <td class="ps-2">
                            <router-link
                                v-if="companySlug"
                                :to="{ name: 'company', params: { slug: companySlug } }"
                                target="_blank"
                                rel="noopener noreferrer">
                                    {{ institution.customer.company_reg_no }}
                            </router-link>   
                        </td>
                    </tr>
                    <tr>
                        <td>DIČ:</td>
                        <td class="ps-2">{{ institution.customer.tax_id }}</td>
                    </tr>
                    <tr>
                        <td>Ulice:</td>
                        <td class="ps-2">{{ institution.customer.address.street }}</td>
                    </tr>
                    <tr>
                        <td>PSČ:</td>
                        <td class="ps-2">{{ institution.customer.address.zip_code }}</td>
                    </tr>
                    <tr>
                        <td>Obec:</td>
                        <td class="ps-2">{{ institution.customer.address.city }}</td>
                    </tr>
                    <tr>
                        <td>Email:</td>
                        <td class="ps-2">{{ institution.customer.email }}</td>
                    </tr>
                </table>
            </div>
            <div class="mt-2 block">
                <InstitutionUsers :institutionId="institutionId" :fromAdmin="fromAdmin" />
            </div>
            <div class="mt-3 block">
                <div>
                    <span>Počet zaplacených Uživatelů: {{ institution.paid_users }}</span>
                    <input v-if="editingPaidUsers" type="number" class="form-control" v-model="newPaidUsers">
                    <button v-if="!editingPaidUsers" @click="editingPaidUsers = !editingPaidUsers"
                        class="btn btn_main ms-3">
                        {{ $t("utils.edit") }}
                    </button>
                    <button @click="sumbitEditedPaidUsers()" class="btn btn_main ms-3" v-if="editingPaidUsers">
                        {{ $t("utils.save") }}
                    </button>
                    <button v-if="editingPaidUsers" @click="editingPaidUsers = !editingPaidUsers"
                        class="btn btn_secondary ms-3">
                        {{ $t("utils.cancel") }}
                    </button>
                </div>
                <div class="mt-2">
                    <span>Zaplaceno do:
                        <i18n-d v-if="institution.paid_to" tag="span" :value="new Date(institution.paid_to)"></i18n-d>
                    </span>

                    <input v-if="editingPaidTo" type="date" class="form-control" v-model="newPaidTo" :min="institution.paid_to">
                    <button v-if="!editingPaidTo" @click="editPaidTo()" class="btn btn_main ms-3">
                        {{ $t("utils.edit") }}
                    </button>
                    <button @click="sumbitEditedPaidTo()" class="btn btn_main ms-3" v-if="editingPaidTo">
                        {{ $t("utils.save") }}
                    </button>
                    <button v-if="editingPaidTo" @click="editingPaidTo = !editingPaidTo" class="btn btn_secondary ms-3">
                        {{ $t("utils.cancel") }}
                    </button>
                </div>
                <div class="mt-2">
                    <span>Platící:
                        <span v-if="institution.paying">Ano</span>
                        <span v-else>Ne</span>
                    </span>
                    <div v-if="editingPaying">
                        <input type="checkbox" class="" v-model="newPaying">
                        <span v-if="newPaying">Ano</span>
                        <span v-else>Ne</span>
                    </div>
                    <button v-if="!editingPaying" @click="editPaying()" class="btn btn_main ms-3">
                        {{ $t("utils.edit") }}
                    </button>
                    <button @click="sumbitEditedPaying()" class="btn btn_main ms-3" v-if="editingPaying">
                        {{ $t("utils.save") }}
                    </button>
                    <button v-if="editingPaying" @click="editingPaying = !editingPaying" class="btn btn_secondary ms-3">
                        {{ $t("utils.cancel") }}
                    </button>
                </div>

                <div class="mt-2">
                    <span>Pokračovat ve vystavování faktur:
                        <span v-if="institution.invoicing_active">Ano</span>
                        <span v-else>Ne</span>
                    </span>
                    <div v-if="editingInvoicing">
                        <input type="checkbox" class="" v-model="newInvoicing">
                        <span v-if="newInvoicing">Ano</span>
                        <span v-else>Ne</span>
                    </div>
                    <button v-if="!editingInvoicing" @click="editInvoicing()" class="btn btn_main ms-3">
                        {{ $t("utils.edit") }}
                    </button>
                    <button @click="submitEditedInvoicing()" class="btn btn_main ms-3" v-if="editingInvoicing">
                        {{ $t("utils.save") }}
                    </button>
                    <button v-if="editingInvoicing" @click="editingInvoicing = false" class="btn btn_secondary ms-3">
                        {{ $t("utils.cancel") }}
                    </button>
                </div>

                <div class="mt-2">
                    <span>Velikost zaplaceného prostoru:
                        <span>{{ institution.max_storage }} GB</span>
                    </span>

                    <div v-if="editingSize">
                        <input type="number" class="form-control" v-model="newSize">
                    </div>
                    <button v-if="!editingSize" @click="editSize()" class="btn btn_main ms-3">
                        {{ $t("utils.edit") }}
                    </button>
                    <button v-if="editingSize" @click="submitEditedSize" class="btn btn_main ms-3">
                        {{ $t("utils.save") }}
                    </button>
                    <button v-if="editingSize" @click="editingSize = !editingSize" class="btn btn_secondary ms-3">
                        {{ $t("utils.cancel") }}
                    </button>
                </div>
                <div v-if="maxStorageB" class="mt-2">
                    <span>Velikost využitého prostoru:
                        <span>{{ usedStorageGB }} GB ({{ usedStoragePercentage }} %)</span>
                    </span>
                </div>
            </div>
            <div class="mt-2 tags block">
                <InstitutionTags :institutionId="institutionId" />
            </div>
            <div class="mt-2 block">
                <div>
                    <span class="pe-2">Plán:</span>
                    <span v-if="institution.plan">{{ institution.plan.plan }}</span>
                </div>
                <div v-if="institution.plan">
                    <div>
                        <span class="pe-2">Cena za uživatele:</span>
                        <span v-if="institution.plan.user_price">{{ institution.plan.user_price }}</span>
                        <span v-else>-</span>
                    </div>
                    <div>
                        <span class="pe-2">Doba:</span>
                        <span v-if="institution.plan.duration">{{ institution.plan.duration }}</span>
                        <span v-else>-</span>
                    </div>
                    <div>
                        <span class="pe-2">Max. velikost souborů:</span>
                        <span v-if="institution.max_storage">{{ institution.max_storage }}</span>
                        <span v-else>-</span>
                    </div>
                    <div>
                        <span class="pe-2">Základní plán:</span>
                        <span v-if="institution.plan.default">
                            <span v-if="institution.plan.default">Ano</span>
                            <span v-else>Ne</span>
                        </span>
                        <span v-else>-</span>
                    </div>
                    <!-- <div>
                        <span class="pe-2">E-maily nových firem:</span>
                        <span v-if="institution.plan.new_companies_email">{{ institution.plan.new_companies_email
                            }}</span>
                        <span v-else>-</span>
                    </div> -->
                </div>
                <div class="mt-3">
                    <button @click="editingPlan = !editingPlan" class="btn btn_main">
                        <span class="pt-1">Změnit</span>
                    </button>
                    <button data-bs-toggle="modal" data-bs-target="#createPlanModal" class="btn btn_main ms-3">
                        <span class="pt-1">Vytvořit nový</span>
                    </button>
                </div>
            </div>
            <div v-if="editingPlan" class="mt-3 w-50 block">
                <div class="mt-3">
                    <select class="form-select" v-model="selectedPlan">
                        <option v-for="plan in plans" :key="plan.id" :value="plan.id">{{ plan.plan }}</option>
                    </select>
                </div>
                <div class="mt-3">
                    <button class="btn btn_main w-100" @click="editPlan()">Uložit</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="createPlanModal" tabindex="-1" aria-labelledby="createPlanModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="createPlanModalLabel">Vytvořit nový</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mt-3">
                        <label>Název:</label>
                        <input type="text" class="form-control" v-model="newPlan.name">
                    </div>
                    <div class="mt-3">
                        <label>Cena za uživatele:</label>
                        <input type="number" class="form-control" v-model="newPlan.user_price">
                    </div>
                    <div class="mt-3">
                        <label>Trvání:</label>
                        <select v-model="newPlan.duration" id="plan-duration" class="form-select">
                            <option v-for="(label, value) in durationChoices" :key="value" :value="value">
                                {{ label }}
                            </option>
                        </select>
                    </div>
                    <div class="mt-3">
                        <label>Cena za GigaByte (GB):</label>
                        <input type="number" class="form-control" v-model="newPlan.price_per_gigabyte">
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn_secondary" data-bs-dismiss="modal">Zrušit</button>
                    <button @click="addPlan()" type="button" class="btn btn_main">Uložit</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InstitutionUsers from '@/components/Institution/InstitutionUsers.vue';
    import InstitutionTags from '@/components/Institution/InstitutionTags.vue';


    export default {
        components: {
            InstitutionUsers,
            InstitutionTags
        },
        data() {
            return {
                institution: {customer: {address:{}}},
                employees: [],
                tags: [],
                durationChoices: {},
                plans: [],
                institutionId: null,
                editingPlan: false,
                selectedPlan: null,
                newPlan: {
                    name: null,
                    base_price: null,
                    user_price: null,
                    duration: null,
                    price_per_gigabyte: null,
                    default: false,
                    new_companies_email: false
                },
                editingPaidTo: false,
                newPaidTo: null,
                editingPaying: false,
                newPaying: false,
                editingPaidUsers: false,
                newPaidUsers: null,
                fromAdmin: null,
                companySlug: "",
                editingSize: false,
                newSize: null,
                totalDocumentSize: null,
                maxStorageB: null,
                editingInvoicing: false,
                newInvoicing: false,
            }
        },
        created() {
            this.institutionId = this.$route.params.institution_id;
            this.fromAdmin = true;
            this.loadData();
        },
        methods: {
            loadData() {
                this.$store.getters.api.get(`/institution/${this.institutionId}/admin`).then(response => {
                    this.institution = response.data.institution;
                    this.employees = response.data.employees;
                    this.tags = response.data.tags;
                    this.totalDocumentSize = response.data.total_document_size;
                    this.maxStorageB = response.data.max_storage_to_bytes;

                    this.newPaying = this.institution.paying;
                    this.newSize = this.institution.max_storage;
                    this.newPaidUsers = this.institution.paid_users;
                    this.newInvoicing = this.institution.invoicing_active;

                    this.loadCompanySlug();
                });
                this.$store.getters.api.get("/plans").then(response => {
                    this.plans = response.data.items;
                });
                this.$store.getters.api.get('/plan-choices').then(response => {
                    this.durationChoices = response.data.choices;
                });
            },
            loadCompanySlug() {
                if (!this.institution?.customer?.company_reg_no) {
                    console.warn("institution.customer.company_reg_no is not available yet.");
                    return;
                }

                this.$store.getters.api
                    .get(`/company/get_slug/${this.institution.customer.company_reg_no}`)
                    .then(response => {
                        this.companySlug = response.data.company_slug;
                    })
                    .catch(error => {
                        console.error("Error loading company slug:", error);
                    });
            },
            editPlan() {
                const selectedPlan = { plan_id: this.selectedPlan };
                this.$store.getters.api.put(`/institution/${this.institutionId}/edit`, selectedPlan).then(response => {
                    this.loadData();
                    this.editingPlan = false;
                })
            },
            addPlan() {
                this.$store.getters.api.post(`/plan/institution/${this.institutionId}/add`, this.newPlan).then(response => {
                    window.location.reload()
                })
            },

            editPaidTo() {
                this.editingPaidTo = !this.editingPaidTo
            },
            editPaying() {
                this.editingPaying = !this.editingPaying;
            },
            sumbitEditedPaidTo() {
                this.$store.getters.api.put(`/institution/${this.institutionId}/edit`, { paid_to: this.newPaidTo }).then(response => {
                    this.loadData();
                    this.editingPaidTo = false;
                })
            },
            sumbitEditedPaying() {
                this.$store.getters.api.put(`/institution/${this.institutionId}/edit`, { paying: this.newPaying }).then(response => {
                    this.loadData();
                    this.editingPaying = false;
                })
            },

            editPaidUsers() {
                this.editingPaidUsers = !this.editingPaidUsers
            },
            sumbitEditedPaidUsers() {
                this.$store.getters.api.put(`/institution/${this.institutionId}/edit`, { paid_users: this.newPaidUsers }).then(response => {
                    this.loadData();
                    this.editingPaidUsers = false;
                })
            },

            editInvoicing() {
                this.editingInvoicing = true;
            },
            submitEditedInvoicing() {
                this.$store.getters.api.put(`/institution/${this.institutionId}/edit`, { invoicing_active: this.newInvoicing })
                    .then(response => {
                        this.loadData();
                        this.editingInvoicing = false;
                    })
                    .catch(error => {
                        console.error("API edit invoicing error:", error)
                    })
            },

            editSize() {
                this.editingSize = true;
            },
            submitEditedSize() {
                this.$store.getters.api.put(`/institution/${this.institutionId}/edit`, { max_storage: this.newSize })
                .then(response => {
                    this.loadData();
                    this.editingSize = false;
                })
                .catch(error => {
                    console.error("API error:", error)
                })
            }
        },
        computed: {
            usedStoragePercentage: function () {
                if(this.totalDocumentSize > 0) {
                    return ((this.totalDocumentSize / this.maxStorageB) * 100).toFixed(0);
                }
                return 0.00;
            },
            usedStorageGB() {
                if (this.totalDocumentSize > 0) {
                    const value = this.totalDocumentSize / 1_073_741_824;

                    if (value < 10) {
                        return (Math.ceil(value * 1000) / 1000).toFixed(3); // 3 desetinná místa
                    } else if (value >= 10 && value <= 100) {
                        return (Math.ceil(value * 100) / 100).toFixed(2); // 2 desetinná místa
                    } else {
                        return (Math.ceil(value * 10) / 10).toFixed(1); // 1 desetinné místo
                    }
                }   
                return "0";
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/scss/abstracts/variables";
    @import "@/scss/base/typography.scss";

    .box {
        background-color: $light-grey;
        color: $black;
    }

    h2 {
        font-size: 40px;
    }

    .content {
        font-size: 20px;
    }

    .color-circle {
        display: inline-block;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid #000;
    }

    svg {
        width: 35px;
        height: 35px;
    }

    .icon_bin {
        color: $purple;
        background-color: transparent;
    }

    .icon_edit {
        stroke: $purple;
        color: $purple;
        background-color: transparent;
    }
</style>
