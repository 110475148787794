<template>
    <div class="row company">
        <div v-if="error" class="my-2 text-center" style="font-size: 40px; color: red;">
            <span class="error">{{ error }}</span>
        </div>
        <div v-if="loading" class="loading-container text-center loading-company">
            <SpinningWheel class="mb-3 mx-auto" />
        </div>
        <div class="col-8">
            <div class="row">
                <div class="col-12 w-100">
                    <CompanyBasicInfo :company="company" :companyAddress="companyAddress"/>
                    <CompanyExecutions class="mt-3" :company="company" />
                    <CompanyInsolvency class="mt-3" :company="company" />
                </div>
                <div :class="{ hidden: !(company.trades && company.trades.length > 0) && !(company.dph && company.dph.length > 0) }"
                    class="col-6 mt-3">
                    <CompanyTrades :company="company" />
                    <CompanyBankAcc class="mt-3" :company="company" />
                    <CompanyDph class="mt-3" :company="company" />
                </div>
                <div class="col-6 mt-3">
                    <CompanyEstablishments :company="company" :companyAddress="companyAddress"/>
                    <CompanyBasicCapital class="mt-3" :company="company" />
                    <CompanyOwners class="mt-3" :company="company" />
                    <CompanyWebsite class="mt-3" :company="company" />
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="row">
                <div class="col-12 w-100">
                    <CompanyYourInfo :company="company" :tags="tags" @reload="loadData" />
                    <CompanyNotes :company="company" class="mt-3 mb-3" @reload="loadData" />
                    <CompanyContacts :company="company" :contacts="contacts" class="mt-3 mb-3" @reload="loadData"/>
                    <CompanyDocuments :company="company" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyBasicInfo from '@/components/CompanyDetail/CompanyBasicInfo.vue';
import CompanyOwners from '@/components/CompanyDetail/CompanyOwners.vue';
import CompanyDph from '@/components/CompanyDetail/CompanyDph.vue';
import CompanyBankAcc from '@/components/CompanyDetail/CompanyBankAcc.vue';
import CompanyTrades from '@/components/CompanyDetail/CompanyTrades.vue';
import CompanyYourInfo from '@/components/CompanyDetail/CompanyYourInfo.vue';
import CompanyNotes from '@/components/CompanyDetail/CompanyNotes.vue';
import CompanyExecutions from '@/components/CompanyDetail/CompanyExecutions.vue';
import CompanyInsolvency from '@/components/CompanyDetail/CompanyInsolvency.vue';
import CompanyBasicCapital from '@/components/CompanyDetail/CompanyBasicCapital.vue';
import CompanyEstablishments from '@/components/CompanyDetail/CompanyEstablishments.vue';
import CompanyDocuments from '@/components/CompanyDetail/CompanyDocuments.vue';
import CompanyWebsite from '@/components/CompanyDetail/CompanyWebsite.vue';
import BaseTemplate from '@/components/templates/BaseTemplate.vue';
import SpinningWheel from '@/components/SpinningWheel.vue';
import CompanyContacts from '../components/CompanyDetail/CompanyContacts.vue';

export default {
    components: {
        CompanyBasicInfo,
        CompanyOwners,
        CompanyDph,
        CompanyBankAcc,
        CompanyTrades,
        CompanyYourInfo,
        CompanyNotes,
        CompanyExecutions,
        CompanyInsolvency,
        CompanyBasicCapital,
        CompanyEstablishments,
        CompanyWebsite,
        BaseTemplate,
        SpinningWheel,
        CompanyDocuments,
        CompanyContacts
    },
    data() {
        return {
            company: {},
            filter: {},
            tags: [],
            companyAddress: [],
            error: false,
            loading: 0,
            contacts: []
        }
    },
    created: function () {
        this.loadData();
    },
    methods: {
        loadData: function () {
            this.loading = 3;
            this.$store.getters.api.get('/company/' + this.$route.params.slug).then(function (response) {
                this.company = response.data.item;
                const data = response.data;
                if (data.error_code == 401) {
                    this.error = (this.$t("error.401"));
                }
            }.bind(this))
            .finally(function () {
                this.loading -= 1;
            }.bind(this));
            this.$store.getters.api.get(`institution/${this.$store.getters.selectedInstitution}/tags`).then(function (response) {
                this.tags = response.data.items;
            }.bind(this))
            .finally(function () {
                this.loading -= 1;
            }.bind(this));
            this.$store.getters.api.get('/company/' + this.$route.params.slug + '/address').then(function (response) {
                this.companyAddress = response.data.items;
            }.bind(this))
            .finally(function () {
                this.loading -= 1;
            }.bind(this));
            this.getContacts();
        },
        getContacts() {
            this.$store.getters.api.get(`/company/${this.$route.params.slug}/company_contact`)
            .then(response => {
                this.contacts = response.data;
            })
            .catch(error => {
                console.log("Error getting contacts:", error);
            })
        },
    }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.hidden {
    display: none;
    margin-top: 0;
}

.company {
    background-color: transparent;
    position: relative;
}

.loading-company {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50px;
}

#map {
    height: 180px;
}
</style>