<template>
    <CompanyBoxContent :title="$t('company.yourDocuments') + ':'">
        <template #data>
            <div v-if="documents && institution">
                <div class="table-responsive">
                    <table class="table table-hover">
                        <thead class="table_head">
                            <tr>
                                <th>{{ $t("company.name") }}</th>
                                <th>{{ $t("company.size") }}</th>
                                <th>{{ $t("general.actions") }}</th>
                            </tr>
                        </thead>
                        <tbody class="align-middle">
                            <tr v-if="documents && documents.length > 0" v-for="document in documents"
                                :key="document.id" class="align-middle">
                                <td>
                                    <span>{{ document.name }}</span>
                                </td>
                                <td>
                                    <span>{{ document.file_size }}</span>
                                </td>
                                <td>
                                    <div class="d-flex">
                                        <button @click="deleteDocument(document.id)" class="btn">
                                            <icon icon="Bin" class="icon_bin" />
                                        </button>
                                        <button @click="downloadDocument(document)" class="btn">
                                            <icon icon="Download" class="download_icon" />
                                        </button>
                                        <button @click="editDocument(document.id)" data-bs-toggle="modal"
                                            data-bs-target="#exampleModal" type="button" class="btn">
                                            <icon icon="Edit" class="edit_icon" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr v-else>
                                <td colspan="3">
                                    <div class="no_documents">
                                        <span>{{ $t("company.noDocuments") }}</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="institution.max_storage" class="text-center mt-3" data-bs-toggle="modal"
                    data-bs-target="#exampleModal">
                    <button class="btn btn_main" type="button" @click="(uploadingFile = true, editingName = false)">
                        <icon icon="Upload" class="me-2" />
                        <span class="align-middle">{{ $t('general.uploadDocuments') }}</span>
                    </button>
                </div>
                <button v-else disabled class="btn btn_main mt-3 btn_disabled disabled " type="button">
                    <span>{{ $t('general.notPaidDocuments') }}</span>
                </button>
            </div>

        </template>
    </CompanyBoxContent>
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal_wrapper">
                <div v-if="loading" class="loading-container text-center loading-company">
                    <SpinningWheel class="mb-3 mx-auto" />
                </div>
                <div class="modal-header mx-auto border-0">
                    <h5 class="modal-title title">{{ $t("general.uploadDocuments") }}</h5>
                </div>
                <div class="modal-body">
                    <div v-if="error" class="my-2 text-center bg-white px-3 py-1 rounded">
                        <span class="error">{{ error }}</span>
                    </div>
                    <div v-if="success" class="my-2 text-center bg-white px-3 py-1 rounded">
                        <span class="success">{{ success }}</span>
                    </div>
                    <div v-if="uploadingFile" class="mb-3">
                        <input type="file" class="form-control" @change="onFileChanged" />
                    </div>
                    <div v-if="uploadingFile" class="mb-3">
                        <label class="form-label">{{ $t("company.name") }}</label>
                        <input type="text" class="form-control" v-model="fileName" />
                    </div>
                    <div v-if="editingName" class="mb-3">
                        <label class="form-label">{{ $t("company.name") }}</label>
                        <input type="text" class="form-control" v-model="editData.fileName" />
                    </div>
                    <div class="modal-footer border-0 d-flex gap-2 justify-content-center">
                        <button type="button" class="btn btn_secondary" data-bs-dismiss="modal" style="width: 130px;">
                            <span>{{ $t('utils.close') }}</span>
                        </button>
                        <button v-if="uploadingFile" @click="submitDocument()" class="btn btn_main"
                            style="width: 130px;">
                            <span>{{ $t("utils.submit") }}</span>
                        </button>
                        <button v-if="editingName" @click="editDocumentSubmit()" class="btn btn_main"
                            style="width: 130px;">
                            <span>{{ $t("utils.submit") }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CompanyBoxContent from "@/components/CompanyBoxContent.vue"
    import SpinningWheel from '@/components/SpinningWheel.vue';
    import { CONFIG } from '@/config.js';

    export default {
        props: {
            company: {
                type: Object,
                required: true
            }
        },
        components: {
            CompanyBoxContent,
            SpinningWheel,
        },
        data() {
            return {
                documents: [],
                file: null,
                fileName: null,
                uploadingFile: false,
                editingName: false,
                editData: {
                    id: null,
                    fileName: null,
                },
                error: null,
                success: null,
                downloadedFile: null,
                institution: null,
                totalDocumentSize: null,
                maxStorage: null,
                loading: false,
            }
        },
        created: function () {
            this.loadData();
        },
        methods: {
            loadData: function () {
                this.loading = false;
                this.$store.getters.api.get(`/company/${this.$route.params.slug}/documents`).then(function (response) {
                    this.documents = response.data.items;
                }.bind(this));
                if (this.$store.getters.selectedInstitution) {
                    const institutionId = this.$store.getters.selectedInstitution;
                    this.$store.getters.api.get(`/institution/${institutionId}`).then(function (response) {
                        this.institution = response.data.institution;
                        this.totalDocumentSize = response.data.total_document_size;
                        this.maxStorage = response.data.max_storage_to_bytes;
                    }.bind(this));
                }
            },
            isOverMaxStorage(fileSize) {
                const totalSize = this.totalDocumentSize + fileSize;
                const maxStorage = this.maxStorage;
                return totalSize > maxStorage;
            },
            onFileChanged: function (event) {
                this.file = event.target.files[0];
                this.success = null;

                const maxSizeBytes = CONFIG.MAX_FILE_SIZE_MB;
                const file = event.target.files[0];
                
                this.fileName = this.file.name;
                this.editData.fileName = this.fileName;

                if (file && file.size > maxSizeBytes) {
                    this.error = this.$t("institution.errors.fileTooLarge");
                    this.file = null;
                    this.fileName = null;
                    this.editData.fileName = null;
                } else {
                    this.file = file;
                    this.error = null;
                }

                if (this.isOverMaxStorage(this.file.size)) {
                    this.error = this.$t("institution.errors.storageLimitExceeded");
                    this.file = null;
                    this.fileName = null;
                    this.editData.fileName = null;
                    return;
                }
            },
            submitDocument: function () {
                if (!this.file || !this.fileName) {
                    this.error = this.$t("institution.errors.noFileOrNameSelected");
                    return;
                }

                this.loading = true;

                let formData = new FormData();
                formData.append('file', this.file);
                formData.append('filename', this.fileName);

                this.$store.getters.api.post(`/company/${this.$route.params.slug}/document/add`, formData, {
                }).then(function (response) {
                    this.success = this.$t('general.documentUploaded');
                    this.uploadingFile = false;
                    this.fileName = null;
                    this.file = null;
                    this.loadData();
                }.bind(this));
            },
            deleteDocument: function (id) {
                this.$store.getters.api.delete(`/company/${this.$route.params.slug}/document/${id}/delete`).then(function (response) {
                    this.loadData();
                }.bind(this));
            },
            editDocument: function (id) {
                this.uploadingFile = false;
                this.editingName = true;
                this.editData.id = id;
            },
            editDocumentSubmit: function () {
                if (!this.editData.fileName) {
                    this.error = this.$t("institution.errors.noName");
                };
                let id = this.editData.id;
                this.$store.getters.api.put(`/company/${this.$route.params.slug}/document/${id}/edit`, { name: this.editData.fileName }).then(function (response) {
                    this.success = this.$t('general.nameChanged');
                    this.editingName = false;
                    this.loadData();
                }.bind(this));
            },
            downloadDocument(documentItem) {
                this.$store.getters.api.get(`/company/${this.$route.params.slug}/document/${documentItem.id}/download`, {
                    responseType: 'blob'
                }).then((response) => {
                    if (response.data) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = window.document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', documentItem.name + documentItem.document_type || 'downloaded document' + documentItem.document_type);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                        this.loadData();
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/scss/abstracts/variables";
    @import "@/scss/base/typography.scss";

    .success {
        color: $green;
    }

    .btn_disabled {
        background-color: $beige !important;
        border: none;

        span {
            color: $black !important;
        }
    }

    .no_documents {
        text-align: start;
        width: 100%;
        border-radius: 35px;
        font-size: 20px;
    }

    .modal_wrapper {
        position: relative;
        padding: 10px 25px 10px 25px;
        border-radius: 65px;

        label {
            color: $white;
            font-weight: 700;
        }
    }

    .icon_bin,
    .download_icon,
    .edit_icon {
        color: $purple;
        stroke: $purple;
        width: 30px;
        height: 30px;

    }

    .loading-company {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 50px;
    }
</style>