<template>
    <div class="chart-wrapper">
        <div class="timeline-buttons d-flex flex-wrap justify-content-center gap-2 mb-4 text-center">
            <button class="btn btn_main me-2" @click="updateData('last_month')" :class="{active: selection==='last_month'}">Minulý měsíc</button>
            <button class="btn btn_main me-2" @click="updateData('this_month')" :class="{active: selection==='this_month'}">Tento měsíc</button>
            <button class="btn btn_main me-2" @click="updateData('one_month')" :class="{active: selection==='one_month'}">1 měsíc</button>
            <button class="btn btn_main me-2" @click="updateData('six_months')" :class="{active: selection==='six_months'}">6 měsíců</button>
            <button class="btn btn_main me-2" @click="updateData('one_year')" :class="{active: selection==='one_year'}">1 rok</button>
            <button class="btn btn_main me-2" @click="updateData('all')" :class="{active: selection==='all'}">Vše</button>
        </div>
        <apexchart v-if="series.length > 0" ref="chart" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    import VueApexCharts from "vue3-apexcharts";
    import { CONFIG } from "@/config";

    export default {
        components: {
            apexchart: VueApexCharts
        },
        props: {
            series: {
                type: Array,
                required: true,
            }
        },
        data() {
            return {
                selection: "", // Aktuální časový výběr (např. "one_month", "six_months")
                chartOptions: {
                    markers: { // Nastavení markerů na lince grafu
                        shape: "circle", // ("circle", "square")
                        size: 6,
                        hover: {
                            size: 10,
                        }
                    },
                    stroke: {
                        width: [5, 2, 2], // Tloušťka čáry
                        curve: "smooth", // Styl linky (např. hladký průběh)
                    },
                    fill: {
                        type: "solid",
                        opacity: [0.2, 0, 0], // Žádné vyplnění
                    },
                    colors: ["#87218D", "#FFA500", "#EF0107"], // Barva linky grafu
                    chart: {
                        id: "area-datetime",
                        type: "area", // Typ grafu (např. "area", "line", "bar")
                        height: 300,
                        zoom: {
                            enabled: false
                        },
                        toolbar: {
                            show: false, // Skryje toolbar grafu (lupa, reset, export atd.)
                        },
                        background: "#FFFFFF" // Barva pozadí grafu
                    },
                    xaxis: {
                        type: "datetime", // Typ osy X jako časová osa
                        labels: {
                            formatter: function (value) {
                                // Formátování dat na ose X na české měsíce a rok
                                const date = new Date(value);
                                
                                const months = [
                                    "Leden", "Únor", "Březen", "Duben", "Květen", "Červen",
                                    "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"
                                ];
                                const month = months[date.getMonth()];
                                
                                return `${date.getDate()}. ${month} ${date.getFullYear()}`;
                            }
                        }
                    },
                    yaxis: { // Přidání mezery nad maximální hodnotu
                        min: 0,
                        max: function (max) {
                            return max + 2; // Přidá 2 k maximální hodnotě
                        },
                        decimalsInFloat: 0, // Zobrazí pouze celá čísla
                    },
                    title: { // Nadpis grafu
                        text: "Platící uživatelé",
                        align: "center",
                        style: {
                            fontSize: "20px",
                            color: "black"
                        }
                    },
                    tooltip: { // Tooltipy, které se zobrazují při najetí myší na data
                        enabled: true,
                        shared: true,
                        theme: "dark", // ("light", "dark")
                        x: {
                            formatter: function (value) {
                                // Formátování data (den.měsíc.rok)
                                const date = new Date(value);
                                return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
                            }
                        },
                    },
                    dataLabels: { // Popisky hodnot (trvalé zobrazení čísel u datových bodů na lince grafu)
                        enabled: false
                    }
                },
            };
        },
        created() {
            this.selection = "one_month"; // Výchozí nastavení tlačítek

            // Počkej na vykreslení komponenty
            this.$nextTick(() => {
                setTimeout(() => {
                    if (this.$refs.chart) {
                        this.updateData(this.selection);
                    }
                }, 300);
            });
        },
        methods: {
            // Funkce pro získání nejnovějšího data v `series`
            getLastDate() {
                if (!this.series.length) return new Date().getTime();
                let allDates = this.series.flatMap(s => s.data.map(d => d[0])); 
                return Math.max(...allDates);
            },
            updateData(timeline) {
                this.selection = timeline
                const now = new Date();
                let start, end;

                switch (timeline) {
                    case "last_month":
                        start = new Date(now.getFullYear(), now.getMonth() - 1, 1).getTime();
                        end = new Date(now.getFullYear(), now.getMonth(), 0).getTime();
                        break;
                    case "this_month":
                        start = new Date(now.getFullYear(), now.getMonth(), 1).getTime();
                        end = new Date(now.getFullYear(), now.getMonth() + 1, 0).getTime();
                        break;
                    case "one_month":
                        start = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()).getTime();
                        end = this.getLastDate();
                        break;
                    case "six_months":
                        start = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate()).getTime();
                        end = this.getLastDate();
                        break;
                    case "one_year":
                        start = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()).getTime();
                        end = this.getLastDate();
                        break;
                    case "all":
                        start = CONFIG.startDateForStats;
                        end = this.getLastDate();
                        break;
                    default:
                        return;
                }

                // Zoom for the selected time period
                this.$refs.chart.zoomX(start, end);
            },
            updateChart() {
                if (this.$refs.chart && this.$refs.chart.updateSeries) {
                    this.$refs.chart.updateSeries(this.series);
                }
            }
        },
        watch: {
            series: { // Sledování změn v `series`
                immediate: true,
                deep: true,
                handler(newData) {
                    if (this.$refs.chart && this.$refs.chart.updateSeries) {
                        this.$refs.chart.updateSeries(newData);
                    } 
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/abstracts/variables";

    .chart-wrapper {
        padding: 15px;
        background-color: $white;
        border-radius: 10px;
    }
    .timeline-buttons button.active {
        color: $black-purple;
        background-color: $white;
        border-color: $black-purple;
    }
</style>