<template>
    <div v-if="!error" class="block">
        <div class="row mt-3">
            <div class="col-4 name_input">
                <input v-model="filters.q" class="form-control name_input" placeholder="Vyhledat podle názvu nebo IČO">
            </div>
            <div class="col-4">
                <VueMultiselect v-model="filters.employees" :options="employees" :searchable="true" :multiple="true"
                    label="name" placeholder="Počet zaměstnanců" class="w-100 multiselect" track-by="id"
                    :select-label="$t('multiselect.pressToSelect')" :deselect-label="$t('multiselect.pressToDeselect')"
                    :selected-label="$t('multiselect.selected')" :limit="3" :limit-text="getLimitText"
                    :showNoResults="false">>
                </VueMultiselect>
            </div>
            <div class="col-4">
                <VueMultiselect v-model="filters.trades" :options="trades" :searchable="true" :multiple="true"
                    group-label="name" group-values="data" label="name" placeholder="Živnosti" class="w-100 multiselect"
                    track-by="id" :select-label="$t('multiselect.pressToSelect')"
                    :deselect-label="$t('multiselect.pressToDeselect')" :selected-label="$t('multiselect.selected')"
                    :limit="3" :limit-text="getLimitText" :showNoResults="false">
                </VueMultiselect>
            </div>
            <div class="col-4 mt-2">
                <VueMultiselect v-model="filters.legalForms" :options="legalForms" :searchable="true" :multiple="true"
                    label="name" placeholder="Typ společnosti" class="w-100 multiselect" track-by="id"
                    :select-label="$t('multiselect.pressToSelect')" :deselect-label="$t('multiselect.pressToDeselect')"
                    :selected-label="$t('multiselect.selected')" :limit="3" :limit-text="getLimitText"
                    :showNoResults="false">
                </VueMultiselect>
            </div>
            <div class="col-4 mt-2">
                <VueMultiselect v-model="filters.tags" :options="tags" :searchable="true" :multiple="true" label="name"
                    placeholder="Štítky" class="w-100 multiselect" track-by="id"
                    :select-label="$t('multiselect.pressToSelect')" :deselect-label="$t('multiselect.pressToDeselect')"
                    :selected-label="$t('multiselect.selected')" :limit="3" :limit-text="getLimitText"
                    :showNoResults="false">
                </VueMultiselect>
            </div>
            <div class="col-4 mt-2">
                <VueMultiselect v-model="filters.creation_date" :options="dateOptions" :multiple="true"
                    placeholder="Datum založení" class="w-100 multiselect" label="label" track-by="id"
                    :select-label="$t('multiselect.pressToSelect')" :selected-label="$t('multiselect.selected')"
                    :deselect-label="$t('multiselect.pressToDeselect')" :showNoResults="false" :limit="2"
                    :limit-text="getLimitText">
                </VueMultiselect>
            </div>
            <div class="col-4 mt-2">
                <VueMultiselect v-model="filters.expired" :options="expired" :multiple="true"
                    placeholder="Aktivní i zaniklé" class="w-100 multiselect" label="label" track-by="id"
                    :select-label="$t('multiselect.pressToSelect')" :selected-label="$t('multiselect.selected')"
                    :deselect-label="$t('multiselect.pressToDeselect')" :showNoResults="false">
                </VueMultiselect>
            </div>
            <div class="col-4 mt-2">
                <VueMultiselect v-model="filters.user_in_contact" :limit="2" :options="users" :multiple="true"
                    placeholder="V kontaktu s" class="w-100 multiselect" label="name" track-by="id"
                    :select-label="$t('multiselect.pressToSelect')" :selected-label="$t('multiselect.selected')"
                    :deselect-label="$t('multiselect.pressToDeselect')" :showNoResults="false"
                    :limit-text="getLimitText">
                </VueMultiselect>
            </div>
            <div class="col-4 mt-2 align-self-center">
                <!-- Button trigger modal -->
                <div class="address_toggle d-flex align-items-center" data-bs-toggle="modal"
                    data-bs-target="#addressModal">
                    <span>{{ $t('company.searchCompanyByAddress') }}</span>
                </div>
                <!-- Modal -->
                <div class="modal fade" id="addressModal" tabindex="-1" aria-labelledby="addressModal"
                    aria-hidden="true">
                    <div class="modal-dialog modal-lg modal-dialog-centered">
                        <div class="modal-content modal_wrapper">
                            <div class="modal-header border-0">
                                <h1 class="modal-title title" id="addressModal">{{ $t('company.searchByAddress') }}
                                </h1>
                            </div>
                            <div class="modal-body p-0">
                                <div class="modal_checkboxes">
                                    <div class="box1 px-4 py-3 d-flex align-items-center">
                                        <input type="checkbox" v-model="manualFilters.address.residence"
                                            class="form-check-input mt-0 me-2" id="residence">
                                        <label for="residence" class="form-label m-0">{{ $t('company.residence')
                                            }}</label>
                                    </div>
                                    <div class="box2 px-4 py-3 d-flex align-items-center">
                                        <input type="checkbox" v-model="manualFilters.address.branch"
                                            class="form-check-input mt-0 me-2" id="branch">
                                        <label for="branch" class="form-label m-0">{{ $t('company.branch') }}</label>
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <label for="branch" class="form-label title px-3">{{ $t('company.searchAddress')
                                        }}</label>
                                    <input type="text" :placeholder="$t('general.writeAddress')"
                                        v-model="manualFilters.address.address" class="form-control address_input">
                                </div>
                                <div class="mt-3">
                                    <label for="branch" class="form-label title px-3">{{ $t('company.searchByRegion')
                                        }}</label>
                                    <VueMultiselect v-model="manualFilters.address.regions" :options="regions"
                                        placeholder="Kraje" class="w-100 address_multiselect" label="name" track-by="id"
                                        :select-label="$t('multiselect.pressToSelect')"
                                        :selected-label="$t('multiselect.selected')"
                                        :deselect-label="$t('multiselect.pressToDeselect')" :showNoResults="false"
                                        :multiple="true" @change="updateDistricts">
                                    </VueMultiselect>
                                </div>
                                <div class="mt-3" v-if="manualFilters.address.regions.length > 0">
                                    <label for="branch" class="form-label">{{ $t('company.searchByDistrict')
                                        }}</label>
                                    <VueMultiselect v-model="manualFilters.address.districts"
                                        :options="availableDistricts" placeholder="Okresy" class="w-100" label="name"
                                        track-by="id" :select-label="$t('multiselect.pressToSelect')"
                                        :selected-label="$t('multiselect.selected')" :multiple="true"
                                        :deselect-label="$t('multiselect.pressToDeselect')" :showNoResults="false">
                                    </VueMultiselect>
                                </div>
                            </div>
                            <div class="modal-footer d-flex justify-content-center gap-3 border-0">
                                <button type="button" class="btn btn_main" data-bs-dismiss="modal">
                                    <span>{{ $t('utils.close') }}</span>
                                </button>
                                <button type="button" class="btn btn_main" @click="applyFilters">
                                    <icon icon="Search" class="search_icon me-2"></icon>
                                    <span>{{ $t('utils.search') }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Clear filters -->
            <div v-if="showClearFilters()">
                <button class="btn clear_filters" @click="clearFilters()">{{ $t('utils.clearFilters') }}</button>
            </div>
        </div>
        <div v-if="loading" class="loading-container mt-3">
            <div class="d-flex align-items-center justify-content-center flex-column">
                <SpinningWheel />
            </div>
        </div>
        <div v-else-if="!loading" style="max-height: 460px; overflow-y: auto;" class="table_container mt-3">
            <table class="table table-hover">
                <thead class="table_head">
                    <tr>
                        <th class="col-2">{{ $t("company.companyName") }}</th>
                        <th class="col-1">{{ $t("company.organizationId") }}</th>
                        <th class="col-3">{{ $t("company.address") }}</th>
                        <th class="col-2">{{ $t("company.inContactWith") }}</th>
                        <th class="col-2">{{ $t("company.tags") }}</th>
                        <th class="col-2"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="company in companies" :key="company.slug" class="align-middle">
                        <td class="col-2">
                            <span v-if="company.name">{{ company.name }}</span>
                            <span v-else>---</span>
                        </td>
                        <td class="col-1">
                            <span v-if="company.organization_id">{{ company.organization_id }}</span>
                            <span v-else>---</span>
                        </td>
                        <td class="col-3">
                            <span v-if="company.address">{{ company.address }}</span>
                            <span v-else>---</span>
                        </td>
                        <td class="col-2 p-0">
                            <div class="d-flex align-items-center user_avatar">
                                <template v-if="company.in_contact_with && company.in_contact_with.length > 0"
                                    class="user_avatar">
                                    <UserAvatar v-for="user in company.in_contact_with" :key="user.id" :user="user"
                                        :user_role="user.role || null" class="me-2 user_avatar" />
                                </template>
                                <span v-else>---</span>
                            </div>
                        </td>
                        <td class="col-2">
                            <div class="d-flex w-100">
                                <span v-if="company.tags.length === 0" class="text-muted">{{ $t('company.noTag')
                                    }}</span>

                                <template v-else>
                                    <span v-for="(tag, index) in company.tags" :key="index">
                                        <Tag :color="tag.color" :bg="tag.bg_color" :text="tag.name" class="me-1" />
                                    </span>
                                </template>
                            </div>
                        </td>
                        <td class="col-2 text-end pe-4">
                            <router-link :to="{ name: 'company', params: { slug: company.slug } }"
                                class="btn btn-primary button_detail p-0">
                                <icon icon="Info"></icon>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="loading-container" style="max-height: 200px; min-height: 200px;" v-if="companies.length == 0">
                <span>{{ $t("general.companiesNotFound") }}</span>
            </div>
        </div>
    </div>
    <div v-if="error">
        <CompaniesNotPaidError :error="error" />
    </div>
    <div v-if="!error && !loading" class="row justify-content-between mx-4 page_counter">
        <Paginator v-model:page="filters.page" :pages="Math.ceil(totalCount / filters.per_page)"
            class="col-auto mx-4" />
        <div class="col-auto mx-2 page_number d-flex">
            <select v-model="filters.per_page" class="form-select align-self-center h-100">
                <option v-for="page in perPage" :key="page" :value="page" class="">
                    <span class="">{{ page }}</span>
                </option>
            </select>
            <icon icon="ArrowUp" class="arrow_up_icon my-auto"></icon>
        </div>
    </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import Paginator from '@/components/Paginator.vue';
import SpinningWheel from '@/components/SpinningWheel.vue';
import UserAvatar from "@/components/CompanyDetail/UserAvatar.vue";
import Tag from '@/components/Tag.vue';
import { mapGetters, mapActions } from 'vuex';
import BaseTemplate from '@/components/templates/BaseTemplate.vue';
import LanguageSelector from '@/components/LanguageSelector.vue';
import CompaniesNotPaidError from '@/components/CompaniesNotPaidError.vue';

export default {
    components: {
        VueMultiselect,
        Paginator,
        SpinningWheel,
        Tag,
        UserAvatar,
        BaseTemplate,
        LanguageSelector,
        CompaniesNotPaidError
    },
    data() {
        return {
            companies: [],
            totalCount: 0,
            employees: [],
            trades: [],
            legalForms: [],
            tags: [],
            perPage: [
                1, 5, 10, 20, 50, 100
            ],
            loading: false,
            debouce: 700,
            timeout: null,
            dateOptions: [
                { id: 'last7', label: this.$t('general.last7Days') },
                { id: 'last30', label: this.$t('general.last30Days') },
                { id: 'last90', label: this.$t('general.last90Days') },
                { id: 'last365', label: this.$t('general.last365Days') }
            ],
            expired: [
                { id: 'false', label: this.$t('general.active') },
                { id: 'true', label: this.$t('general.expired') }
            ],
            users: [],
            error: false,
            regions: [],
            availableDistricts: [],
            currentPage: null
        }
    },
    computed: {
        ...mapGetters(['filters']), // Map Vuex getters
        manualFilters: {
            get() {
                return this.$store.getters.manualFilters;
            },
            set(value) {
                this.$store.commit('setManualFilters', value);
            }
        },
        userName() {
            return this.users.map(user => {
                return {
                    ...user,
                    displayName: user.name || user.username
                }
            })
        },
        updateDistricts: function () {
            if (this.manualFilters.address.regions && this.manualFilters.address.regions.length > 0) {
                var activeRegions = this.manualFilters.address.regions;

                this.availableDistricts = activeRegions.flatMap(region => region.districts);

            } else {
                this.availableDistricts = [];
            }
        },
    },
    created: function () {
        this.loadData();
    },
    watch: {
        filters: {
            handler(newFilters) {
                if (this.currentPage === null) {
                    // Set new page if its null
                    this.currentPage = newFilters.page || 1;
                }
                if (this.currentPage !== newFilters.page) {
                    // if site changed, update `currentPage`
                    this.currentPage = newFilters.page || 1; // Update value to new site
                } else {
                    // If site is the same, something else changed
                    this.currentPage = 1;
                    this.filters.page = 1; // Reset both values to 1
                }
                this.loadCompanies();
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        ...mapActions(['updateFilters']), // Map Vuex actions
        loadData: function () {
            this.loadCompanies();

            this.$store.getters.api.get('/employees').then(function (response) {
                this.employees = response.data.items;
            }.bind(this))

            this.$store.getters.api.get('/trades').then(function (response) {
                this.trades = response.data.items;
            }.bind(this))

            this.$store.getters.api.get('/legal_forms').then(function (response) {
                this.legalForms = response.data.items;
            }.bind(this))

            this.$store.getters.api.get('/regions').then(function (response) {
                this.regions = response.data.regions;
            }.bind(this))

            this.$store.getters.api.get(`institution/${this.$store.getters.selectedInstitution}/tags`).then(function (response) {
                this.tags = [{ id: 'no_tag', name: this.$t('company.noTag') }, ...response.data.items];
            }.bind(this))
            this.$store.getters.api.get(`institution/${this.$store.getters.selectedInstitution}/users`).then(function (response) {
                this.users = [{ id: 'no_user', name: this.$t('company.noUser') }, ...response.data.items];
            }.bind(this))

        },
        loadCompanies: function () {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(function () {
                var params = {
                    ...this.filters
                };
                if (params.creation_date) {
                    var creation_date = [];
                    params.creation_date.forEach(element => {
                        creation_date.push(element.id);
                    })
                    params.creation_date = creation_date;
                }

                if (params.expired) {
                    var expired = [];
                    params.expired.forEach(element => {
                        expired.push(element.id);
                    })
                    params.expired = expired;
                }
                if (params.user_in_contact) {
                    var users = []
                    params.user_in_contact.forEach(element => {
                        if (element.id === 'no_user') {
                            params.no_user = true;
                            params.user_in_contact = null;
                        }
                        else {
                            params.no_user = false;
                            users.push(element.id)
                        }
                    });
                    params["users_in_contact"] = users
                }

                if (params.employees) {
                    var employees = []
                    params.employees.forEach(element => {
                        employees.push(element.id);
                    });
                    params.employees = employees;
                }

                if (params.trades) {
                    var trades = []
                    params.trades.forEach(element => {
                        trades.push(element.id);
                    });
                    params.trades = trades;
                }

                if (params.legalForms) {
                    var legalForms = []
                    params.legalForms.forEach(element => {
                        legalForms.push(element.id);
                    });
                    params.legalForms = legalForms;
                }

                if (params.tags) {
                    var tags = [];
                    params.tags.forEach(element => {
                        if (element.id !== 'no_tag') {
                            tags.push(element.id);
                        }
                        else {
                            tags.push('no_tag');
                            params.no_tag = true;
                        }
                    });
                    params.tags = tags;
                }

                this.loading = true;
                this.error = false
                var urlParams = ""
                if (params.page || params.per_page) {
                    urlParams = "?"
                    if (params.page && params.per_page) {
                        urlParams += "page=" + params.page + "&per_page=" + params.per_page
                    }
                    else if (params.page) {
                        urlParams += "page=" + params.page
                    }
                    else if (params.per_page) {
                        urlParams += "per_page=" + params.per_page
                    }
                }
                this.$store.getters.api.post('/companies' + urlParams, params).then(function (response) {
                    const data = response.data;
                    this.companies = response.data.results;
                    this.totalCount = response.data.count;
                    this.loading = false;

                    if (data.error_code == 401) {
                        this.error = (this.$t("error.401"));
                    }

                }.bind(this))
            }.bind(this), this.debouce);
        },
        applyFilters() {
            this.$store.commit('setManualFilters', this.manualFilters);

            const combinedFilters = { ...this.filters, address: this.manualFilters.address };
            this.updateFilters(combinedFilters);
            this.loadCompanies();
        },
        getLimitText(count) {
            return this.$t('multiselect.andMore', { count });
        },
        showClearFilters: function () {
            if (
                (this.filters.q && this.filters.q.length > 0) ||
                (this.filters.employees && this.filters.employees.length > 0) ||
                (this.filters.trades && this.filters.trades.length > 0) ||
                (this.filters.legalForms && this.filters.legalForms.length > 0) ||
                (this.filters.tags && this.filters.tags.length > 0) ||
                (this.filters.creation_date && this.filters.creation_date.length > 0) ||
                (this.filters.expired && this.filters.expired.length > 0) ||
                (this.filters.user_in_contact && this.filters.user_in_contact.length > 0) ||
                (this.manualFilters.address.residence || this.manualFilters.address.branch || this.manualFilters.address.address) ||
                (this.manualFilters.address.regions.length > 0 || this.manualFilters.address.districts.length > 0)
            ) {
                return true;
            }
            return false;
        }
        ,
        clearFilters() {
            this.$store.commit('updateFilters', {
                per_page: 10,
                page: 1,
                creation_date: null,
                expired: null,
                employees: [],
                trades: [],
                legalForms: [],
                tags: [],
                user_in_contact: null,
            });

            this.$store.commit('setManualFilters', {
                address: {
                    residence: false,
                    branch: false,
                    address: '',
                    regions: [],
                    districts: []
                }
            });
            this.loadCompanies();
        }
    },
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.name_input {
    border-radius: 65px !important;
    color: $dark-grey;
    height: 50px;

    input:focus {
        padding-left: 25px;
    }
}

.name_input::placeholder {
    color: $black;
    opacity: 0.9;
    font-size: 18px;
    font-weight: 400;
    padding: 8px;
}

::v-deep .multiselect,
::v-deep .multiselect__tags {
    border-radius: 65px !important;
}

::v-deep .address_multiselect {

    .multiselect__tags,
    .multiselect-caret,
    .multiselect__placeholder {
        cursor: pointer;
        max-height: 45px;
        font-size: 1rem;
        padding: 4px 20px 40px 10px;
    }
}

.address_toggle {
    background-color: $white;
    color: $dark-grey;
    border-radius: 65px;
    height: 50px;
    cursor: pointer;

    span {
        padding: 20px;
        font-size: 18px;
        font-weight: 400;
    }
}

.modal_wrapper {
    border-radius: 65px;
    padding: 10px 25px 10px 25px;
    color: $white;

    .modal_checkboxes {
        color: $black;
        background-color: $white;
        border-radius: 35px;
        font-size: 18px;

        input {
            cursor: pointer;
            border-radius: 50px;
            border: 2px solid $dark-purple;
            width: 28px;
            height: 28px;
        }

        .form-check-input:checked {
            background-color: $dark-purple;
        }

        .box2 {
            color: $black;
            background-color: $grey;
            border-radius: 35px;
        }
    }

    .address_input {
        border-radius: 35px;
        padding: 10px 10px 60px 20px;
    }

    .search_icon {
        color: $white;
        stroke: $white;
    }
}

.clear_filters {
    color: $white;
    background-color: transparent;
    font-weight: 700;
    text-decoration: underline;
}

.button_detail {
    border: none;
    background-color: transparent;

    svg {
        width: 42px;
        height: 42px;
    }
}

.button_detail:hover {
    background: transparent;
    border: none;

}

.button_detail:focus {
    background: none;
    border: none;
}

.loading-container {
    min-height: 500px;
    /* Minimum height to prevent layout shift */
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;

    span {
        color: black;
    }
}

.page_counter {
    margin-top: 1.5%;
    border-radius: 50px;
}

.page_number {
    background: linear-gradient(90deg, $purple 26%, $light-purple 100%);
    border: none;
    border-radius: 35px;
    height: 44px;
    width: 190px;

    .form-select {
        background: none;
        border: none;
        color: $white;
        font-weight: 400;
        padding-top: 7px;
        font-size: 22px;
        font-family: 'Rubik One Regular', sans-serif;

        option {
            margin: 0;
            text-align: right;
            font-size: 20px;
            color: $black;
        }
    }

    .form-select:focus {
        outline: none;
        border: none;
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    svg {
        stroke: $white;
        text-align: left;
        margin-left: -30px;
    }
}
</style>